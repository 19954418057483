<template>
  <div class="WhiteLabel">
    <KTCard :title="$t('GENERAL.WAIT_CHANGING_WHITE_LABEL')">
      <template v-slot:body>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTCard from "@/view/content/Card";
import store from "@/core/services/store";
import { UPDATE_WHITE_LABEL } from "@/core/services/store/auth.module";
import TheToaster from "@/core/services/the-toaster";
export default {
  name: "Whitelabel",
  components: { KTCard },
  computed: {
    ...mapGetters({ breadcrumbs: "breadcrumbs", layoutConfig: "layoutConfig", currentUser: "auth/currentUser" }),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  mounted() {
    if (this.currentUser.white_label_enabled) {
      TheToaster.info("GENERAL.USER_PARTNER_INFO");
      this.$router.push({ name: "dashboard" });
    } else {
      store
        .dispatch("auth/" + UPDATE_WHITE_LABEL, this.$route.params.whiteLabelSlug)
        .then(() => {
          // this.$router.push({ name: "auth-signin" });
          // window.location.reload();
        })
        .catch(() => {})
        .finally(() => {
          this.$router.push({ name: "auth-signin" });
        });
    }
  },
};
</script>
